import React from 'react';
import '../styles/App.css';
import '../styles/Fonts.css';

function Contact() {
  return (
    <div>
      <p className='title'>CONTACT US:</p>
      <p className='minititle'>1-210-558-3733</p>
      <p className='minititle'>jfurst@momsat.com</p>
      <p className='minititle'>nvasquez@momsat.com</p>
    </div>
  );
}

export default Contact;
